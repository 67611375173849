export const errors = {
  loginFailed: 'Error al iniciar de sesión, vuelva a intentarlo',
  methodNotAllowed: 'Method not allowed',
  popupBlocked: 'Por favor, active las ventanas emergentes y vuelva a intentarlo.',
  popupClosedByUser: 'Error: Has cerrado la ventana de inicio de sessión',
  requestFailed: 'Ha ocurrido un error al realizar la solicitud',
  sessionNotFound: 'Sesión no encontrada',
  tryAgain: 'Error, inténtelo nuevamente',
  unauthorized: 'No tiene permisos para acceder a esta sección',
  userIdIsRequired: 'El id del usuario es requerido',
  userNotFound: 'Error Usuario no encontrado.',
  userNotPlayInCasino: 'Para poder jugar, contacte a soporte',
  errorUserNotFound: 'Error Usuario no encontrado.',
  errorRequestFailed: 'Ha ocurrido un error al realizar la solicitud',
  merchantIDMismatch: 'Merchant ID mismatch',
  noAccessToEnvVariable: 'No access to the variable',
  notADeposit: 'Not a deposit',
  agentNotFound: 'Agente no encontrado',
  agentBalanceError: 'Error al verificar el saldo del agente',
  insufficientBalance: 'Insufficient balance',
  invalidHMAC: 'Invalid HMAC',
  errorGettingData: 'Error al obtener los datos',
  invalidSession: 'Sesion invalida',
  prizeNotFound: 'Premios no encontrados',
  prizeNotApprovedOrNotFound: 'Premio no encontrado o no aprobado',
  invalidPrizeCode: 'Código de premio inválido',
  payPrizeError: 'Pago de premio no exitoso',
  transactionsNotFound: 'Transacciones no encontradas',
  emailNotFound: 'Error, no se encontró el email',
  emailIsAlreadyRegistered: 'El email ya se encuentra registrado',
  processFailed: 'Error, al procesar la solicitud, inténtalo de nuevo',
  phoneAlreadyRegistered: 'El número de teléfono ya está registrado',
  phoneUpdateSuccess: 'Número de teléfono actualizado',
  phoneUpdateError: 'Error al actualizar el número de teléfono',
  invalidPhoneNumber: 'Número de teléfono inválido',
  errorFetchingGamesByCategory: 'Error al obtener los juegos por categoría',
  errorFetchingGamesByProvider: 'Error al obtener los juegos por proveedor',
  errorFetchingGamesBySearch: 'Error al obtener los juegos por búsqueda',
};
