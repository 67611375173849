'use client';

import { memo, Suspense } from 'react';

import dynamic from 'next/dynamic';

import { useSession } from 'next-auth/react';

import { isDesktop } from 'react-device-detect';

import { useAuthStore } from '@/stores/userStore';

import {
  AUTHENTICATED_SESSION_STATUS,
  LOADING_SESSION_STATUS,
} from '@/constants/core';

import NavigationBar from '@/components/navbar/NavigationBar';
import PublicNavBar from '@/components/navbar/PublicNavbar';

import LoadingSpinner from '../common/LoadingSpinner';

const LazySideNavbar = dynamic(() => { return import('@/components/navbar/SideBar'); }, { ssr: false });

const LazyRegistrationPopup = dynamic(() => { return import('@/components/modals/RegistrationPopup'); }, { ssr: false });

const LazyNavBarAuthenticated = dynamic(() => { return import('@/components/navbar/NavBarAuthenticated'); }, { ssr: false });

const NavigationStructure = () => {
  const {
    account,
  } = useAuthStore();

  const { status } = useSession();

  if (status === LOADING_SESSION_STATUS) {
    return null;
  }

  return (
    <>
      <div className="grid gap-0">
        {isDesktop && (
          <div className="grid col-start-1 col-end-2 row-start-2 row-end-4">
            <Suspense fallback={<LoadingSpinner />}>
              <LazySideNavbar />
            </Suspense>
          </div>
        )}
        <div className="grid col-start-1 col-end-4 row-start-1 row-end-2 w-full fixed z-50">
          {status === AUTHENTICATED_SESSION_STATUS
            && account?.fullProfile !== undefined && (
            <Suspense fallback={<LoadingSpinner />}>
              <LazyRegistrationPopup
                isOpen={account?.fullProfile}
                account={account}
              />
            </Suspense>
          )}
          {status === AUTHENTICATED_SESSION_STATUS ? (
            <LazyNavBarAuthenticated />
          ) : (
            <PublicNavBar />
          )}
        </div>
      </div>
      {!isDesktop && <NavigationBar />}
    </>
  );
};

export default memo(NavigationStructure);
